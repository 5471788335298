<template>
  <div class="py-10">
    <div class="mx-auto max-w-7xl sm:px-6 lg:px-8">
      <div class="relative px-6 pt-40 overflow-hidden bg-gray-900 bg-[url(/images/elegant-passenger-bg.webp)] bg-cover bg-top shadow-xl sm:rounded-3xl sm:px-10 lg:py-24 md:px-12 lg:px-20">
        <div class="relative max-w-xl text-xl italic text-white drop-shadow-lg">
          <p>Our mission is to connect customers with the best drivers throughout Europe. Our goal is to make our <strong>customers' lives easier</strong> and at the same time be a reliable partner they can always trust.</p>
          <p>We achieve this by carefully selecting all our drivers and subsequently providing them with intensive training, ensuring they are always prepared to deliver top-notch services to even the most demanding customers.</p>
          <UButton variant="outline" to="/about/mission" label="Read more" color="white" trailing-icon="i-heroicons-arrow-right-20-solid" class="mb-10" />
        </div>
      </div>
    </div>
  </div>
</template>
